<template>
  <v-container class="white-bg main-auth-section font-Roboto" fill-height>
    <v-row>
      <v-col cols="12" md="12">
        <img
          :src="require('@/assets/images/login_logo_mask.png')"
          class="auth-page-background"
        />
        <div
          class="d-flex justify-space-between flex-column align-center full-height"
        >
          <div
            class="full-height d-flex justify-center flex-column align-center"
          >
            <div>
              <v-img
                :src="require('@/assets/images/logo_vertical.png')"
                width="280px"
                max-height="auto"
                class="my-auto"
              ></v-img>
            </div>
            <div class="text-center white--text">日報管理</div>
          </div>
          <div class="auth-btns pt-6 pb-14">
            <v-btn
              class="btn-primary font-20px white--text py-6 border-radius-10px"
              block
              text
              :to="{ name: 'register' }"
              >{{ $t("register_text") }}</v-btn
            >
            <v-btn
              class="btn-light-text-border font-20px py-6 mt-5 border-radius-10px"
              block
              text
              :to="{ name: 'login' }"
            >
              <span class="white--text">{{ $t("login_text") }}</span>
            </v-btn>
            <div class="helpful-links d-flex justify-center align-center">
              <v-btn
                :to="{ name: 'terms_and_conditions' }"
                text
                class="px-0 white--text"
                >利用規約</v-btn
              >
              <span class="mx-1 grey-color">|</span>
              <v-btn
                :to="{ name: 'privacy_policy' }"
                text
                class="px-0 white--text"
                >プライバシーポリシー</v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" src="./style.scss" scope></style>
